import React, { useEffect, useContext } from 'react';
import { graphql } from 'gatsby';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import PageLayout from '@components/layout/PageLayout';
import { ServiceHero } from '@components/layout/PageHero';
import TabContext from 'react-bootstrap/TabContext';
import Testimonial from '@components/Testimonial';
import * as styles from '@css/modules/service.module.scss';
import { MPEventData, useMPEvent } from '@util/mixpanel';

import { ContentSection } from '@src/components/ContentSection';
import { Action, ActionData } from '@src/components/Action';

const TabNavLink = ({ text, eventKey, ...props }: TabNavLinkProps) => {
  const currentEventKey = useContext(TabContext);
  const open = currentEventKey?.activeKey === eventKey;

  return (
    <Nav.Link
      className={`${styles.tab} ${open ? styles.active : ''}`}
      eventKey={eventKey}
      {...props}
    >
      {text}
    </Nav.Link>
  );
};

const DemographicContent = ({
  sectionHeader,
  sectionBody,
  proof,
  actions,
  demographic,
  analyticData,
}: DemographicContentProps) => {
  return (
    <>
      <Row>
        <Col xs={12} lg={5} className="py-4">
          <h2 className="font-weight-bold pb-2">{sectionHeader}</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: sectionBody.childMarkdownRemark.html,
            }}
          ></div>
        </Col>
        <Col xs={12} lg={{ offset: 2, span: 5 }} className="mb-5 pb-5">
          <Testimonial data={proof} />
        </Col>
      </Row>
      <Row className="d-lg-flex justify-content-center">
        {actions.map((action: ActionData) => {
          return (
            <Col xs={12} lg={6} className="mb-5" key={action.id}>
              <Action
                data={action}
                demographic={demographic}
                analyticData={analyticData}
              />
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default function Service({
  data: { contentfulServicePage },
  pageContext,
}: any) {
  const {
    node_locale: nodeLocale,
    pageTitle,
    service,
    header,
    subTitle,
    subTitleLineTwo,
    youthTab,
    youthSectionHeader,
    youthSectionBody,
    youthProof,
    youthActions,
    adultTab,
    adultSectionHeader,
    adultSectionBody,
    adultProof,
    adultActions,
    sections,
  } = contentfulServicePage;
  const { trackPageLoad, trackPageInteraction } = useMPEvent();
  const trackingData = {
    language: nodeLocale.toLowerCase(),
    url: `/${nodeLocale}/service/${service.title.toLowerCase()}`,
    url_name: `service - ${service.title}`,
  };
  useEffect(() => {
    trackPageLoad(trackingData);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    // @ts-expect-error
    <PageLayout
      translations={pageContext.translations}
      node_locale={nodeLocale}
      page={pageContext.pageName}
      pageTitle={pageTitle}
    >
      <ServiceHero data={{ header, subTitle, subTitleLineTwo }} />
      <Tab.Container defaultActiveKey="adult">
        <div className={`${styles.tabContainer} bg-secondary`}>
          <Container fluid="lg">
            <Nav variant="tabs" className={styles.tabs}>
              <Nav.Item className={styles.tabContainer}>
                <TabNavLink
                  text={adultTab}
                  eventKey="adult"
                  onClick={() => {
                    trackPageInteraction({
                      ...trackingData,
                      element: 'tab',
                      action: 'open',
                      value: 'adult',
                    });
                  }}
                />
              </Nav.Item>
              <Nav.Item className={styles.tabContainer}>
                <TabNavLink
                  text={youthTab}
                  eventKey="youth"
                  onClick={() => {
                    trackPageInteraction({
                      ...trackingData,
                      element: 'tab',
                      action: 'open',
                      value: 'youth',
                    });
                  }}
                />
              </Nav.Item>
            </Nav>
          </Container>
        </div>
        <div className={`${styles.background} pt-4 pt-lg-5`}>
          <Container className="d-flex justify-content-start">
            <Row>
              <Col>
                <Tab.Content>
                  <Tab.Pane eventKey="adult">
                    <DemographicContent
                      demographic="adult"
                      sectionHeader={adultSectionHeader}
                      sectionBody={adultSectionBody}
                      actions={adultActions}
                      proof={adultProof}
                      analyticData={trackingData}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="youth">
                    <DemographicContent
                      demographic="youth"
                      sectionHeader={youthSectionHeader}
                      sectionBody={youthSectionBody}
                      actions={youthActions}
                      proof={youthProof}
                      analyticData={trackingData}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Container>
        </div>
      </Tab.Container>
      <Container>
        <Row>
          <Col xs={12} className="m-lg-5">
            {sections.map((section) => {
              return (
                <div key={section.sectionId}>
                  <h2
                    className={`text-bolder my-4 mb-lg-5 text-lg-center`}
                    id={section.sectionId}
                  >
                    {section.header}
                  </h2>
                  <ContentSection section={section} />
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>
    </PageLayout>
  );
}

export interface TabNavLinkProps {
  text: string;
  eventKey: string;
}

export interface DemographicContentProps {
  sectionHeader: string;
  sectionBody: any;
  proof: any;
  actions: ActionData[];
  demographic: string;
  analyticData: MPEventData;
}

export const query = graphql`
  query($node_locale: String!, $title: String!) {
    contentfulServicePage(
      node_locale: { eq: $node_locale }
      service: { title: { eq: $title } }
    ) {
      node_locale
      pageTitle
      service {
        title
      }
      header
      subTitle
      subTitleLineTwo
      youthTab
      youthSectionHeader
      youthSectionBody {
        childMarkdownRemark {
          html
        }
      }
      youthProof {
        author
        bg
        re
        quote {
          childMarkdownRemark {
            html
          }
        }
        style
      }
      youthActions {
        ...Action
      }
      adultTab
      adultSectionHeader
      adultSectionBody {
        childMarkdownRemark {
          html
        }
      }
      adultProof {
        author
        bg
        re
        quote {
          childMarkdownRemark {
            html
          }
        }
        style
      }
      adultActions {
        ...Action
      }
      sections {
        ...ContentSection
      }
    }
  }
`;
