import React from 'react';

import Card from 'react-bootstrap/Card';

import * as styles from '@css/modules/testimonial.module.scss';
import Quote from '@css/img/svg/left-quote.svg';

export default function Testimonial({ data }) {
  return (
    <div className={styles.testimonial}>
      <Card className={styles.card}>
        {data.style === 'stat' ? (
          <Card.Body className={styles.stat}>
            <div>
              <p className={`text-secondary ${styles.statPercentage}`}>
                {data.re}%
              </p>
              <div
                className={`text-secondary ${styles.quote}`}
                dangerouslySetInnerHTML={{
                  __html: data.quote.childMarkdownRemark.html,
                }}
              />
            </div>
            {data.author ? (
              <p className={styles.author}>{data.author}</p>
            ) : null}
          </Card.Body>
        ) : (
          <Card.Body className={styles.basic}>
            <div className="d-flex flex-column justify-content-start">
              <Quote className={`text-secondary ${styles.quotes}`} />
              <div
                className={`text-secondary ${styles.quote} text-bold`}
                dangerouslySetInnerHTML={{
                  __html: data.quote.childMarkdownRemark.html,
                }}
              />
            </div>
            <div>
              {data.author ? (
                <p className={styles.author}>{data.author}</p>
              ) : null}
              {data.re ? <p className={styles.tagLine}>{data.re}</p> : null}
            </div>
          </Card.Body>
        )}
      </Card>
    </div>
  );
}
