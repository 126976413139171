import { graphql, Link } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import React from 'react';
import { Button, Card } from 'react-bootstrap';

import { MPEventData, useMPEvent } from '@util/mixpanel';
import * as styles from '@css/components/action/action.module.scss';
import { richTextOptions } from '@src/util/richTextOptions';

const ActionLink = ({ text, url, ...props }: ActionLinkProps) => {
  if (/^(http|tel)?/i.test(url)) {
    return (
      <Button href={url} {...props}>
        {text}
      </Button>
    );
  }

  return (
    <Link to={url} {...props}>
      {text}
    </Link>
  );
};

export const Action = ({ data, demographic, analyticData }: ActionProps) => {
  const { trackPageInteraction } = useMPEvent();
  const {
    title,
    subtitle,
    buttonLink,
    buttonText,
    subText,
    details,
    providerSlug,
    elementSlug,
  } = data;
  const trackingData = {
    ...analyticData,
    action: 'click',
  } as const;

  const handleButtonClick = () => {
    // Send tracking event
    if (elementSlug) {
      const value = providerSlug
        ? demographic
        : `${providerSlug}_${demographic}`;
      trackPageInteraction({
        ...trackingData,
        element: elementSlug,
        value,
      });
    }
  };

  return (
    <Card className={`${styles.card}`}>
      <Card.Header className={`p-lg-4 mx-2 mx-lg-5 ${styles.cardHeader}`}>
        <h3 className={styles.title}>{title}</h3>
        <span className={styles.subtitle}>{subtitle}</span>
        <ActionLink
          text={buttonText}
          url={buttonLink}
          className={`btn btn-primary ${styles.actionLink} mx-lg-5 mt-3`}
          onClick={handleButtonClick}
        />
        <span className={styles.subText}>{subText}</span>
      </Card.Header>
      <Card.Body className={`${styles.cardBody}`}>
        <div className="px-lg-4">
          {renderRichText(details, richTextOptions)}
        </div>
      </Card.Body>
    </Card>
  );
};

export interface ActionLinkProps extends React.HTMLProps<Button> {
  text: string;
  url: string;
}

export interface ActionProps {
  data: ActionData;
  demographic?: string;
  analyticData: MPEventData;
}

export interface ActionData {
  id: string;
  title: string;
  subtitle?: string;
  buttonLink: string;
  buttonText: string;
  subText?: string;
  providerSlug?: string;
  elementSlug?: string;
  details: {
    raw: string;
  };
}

export const query = graphql`
  fragment Action on ContentfulAction {
    id: contentful_id
    title
    subtitle
    buttonLink
    buttonText
    subText
    providerSlug
    elementSlug
    details {
      raw
      references {
        ... on ContentfulAsset {
          contentful_id
          __typename
          file {
            url
            contentType
          }
          fluid(maxWidth: 500) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;
