// extracted by mini-css-extract-plugin
export const parent = "testimonial-module--parent--2hWKU";
export const wrapper = "testimonial-module--wrapper--2eReF";
export const headerRow = "testimonial-module--headerRow--juIHS";
export const testimonial = "testimonial-module--testimonial--33VVw";
export const card = "testimonial-module--card--EDW5h";
export const quotes = "testimonial-module--quotes--32Pqq";
export const basic = "testimonial-module--basic--3xrhp";
export const stat = "testimonial-module--stat--5k-Gl";
export const statPercentage = "testimonial-module--statPercentage--2_U8r";
export const quote = "testimonial-module--quote--3Tvu3";
export const author = "testimonial-module--author--3I8SG";
export const tagLine = "testimonial-module--tagLine--1qmCt";